import "./app.scss";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from './ui/Header';
import MeddishScreen from "./ui/meddish/MeddishScreen";
import QuotesScreen from "./ui/quotes/QuotesScreen";
import TimelineScreen from "./ui/timeline/TimelineScreen";
import TodoScreen from "./ui/todo/TodoScreen";
import styled from 'styled-components';

export default function App() {
    return (
        <StyledApp>
            <BrowserRouter>
                <Header />
                <Switch>
                    <Route path="/quotes">
                        <QuotesScreen />
                    </Route>
                    <Route path="/meddish">
                        <MeddishScreen />
                    </Route>
                    <Route path="/todo">
                        <TodoScreen />
                    </Route>
                    <Route path="/">
                        <TimelineScreen />
                    </Route>
                </Switch>
            </BrowserRouter>
        </StyledApp>
    )
}

const StyledApp = styled.div`
  width: 100%;
  height: 100%;
`
