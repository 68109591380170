import styled from "styled-components"

const taskNames = [
    "Illimited snuggles",
    "Snuggle again",
    "Get Ale to say 'unlimited'",
    "Play Monopoly",
    "Have a bath together",
    "Get rich and develop ToonTracker together in the Caribbean",
    "Have a picnic",
    "Minecraft",
    "Make bloody PANCAKES - he means crepes",
    "Get multiple ice creams",
    "Surprise visit to Aleeeeeeee",
    "69 when both phones are 69%",
    "Buy a red and lilac coffee cup",
    "Put sus sticker on a stick",
    "Go camping",
    "Go to a miniature golf course",
    "Play blackjack with chicken nuggets",
    "Go to Disneyland California in 2031",
    "Visit Diana's resting place in 2031",
    "Make the weirdoughmeter",
    "Gift Mehdi a crepe maker - she means pancakes - SUS",
    "Get chicken niggets and sushi rolls when we get our own place",
    "Useless 'I want you' app jajajaja",
    "Make a dice to pick food type to have that says: Italian / BBQ & beef / chicken / seafood / Asian / salads & wraps",
    "Dress up as a ladybird and hug Ale with all arms"
]

export default function TodoScreen() {
    let tasks: JSX.Element[] = []

    taskNames.forEach(task => {
        tasks.push(IncompleteTask(task))
    })

    return (
        <StyledTodoScreen>
            <Header>To-Do</Header>
            <StyledList>
            {tasks}
            </StyledList>
        </StyledTodoScreen>
    )
}

const StyledTodoScreen = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 26%;
    margin-top: 36px;
    margin-bottom: 40px;
`
const StyledList = styled.div``

const Header = styled.div`
    font-size: 40px;
    font-family: Catamaran;
    margin-bottom: 12px;
`

function IncompleteTask(name: string) {
    return (
        <StyledIncompleteTask>
            <StyledIcon className="material-icons-outlined">radio_button_unchecked</StyledIcon>
            <StyledTaskName>{name}</StyledTaskName>
        </StyledIncompleteTask>
    )
}

const StyledIncompleteTask = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
`

const StyledIcon = styled.span`
    width: 20px;
    height: 20px;
`

const StyledTaskName = styled.div`
    font-size: 28px;
    font-family: 'Catamaran', san-serif;
    margin-left: 12px;
`