import styled from "styled-components"

export default () => {
    return (
        <StyledSlogan>
            <Left>Like two puzzle pieces...</Left>
            <Right>...we fit together</Right>
        </StyledSlogan>
    )
}

const StyledSlogan = styled.div`
    display: flex;
    width: 100%;
    margin-top: 36px;
    position: absolute;
`

const Left = styled.p`
    font-size: 36px;
    font-family: 'Niconne', cursive;
    margin-left: 13%;
    opacity: 0.9;
    @media screen and (max-width: 720px) {
        width: 120px;
        margin-left: 5%;
        margin-top -8px;
        font-size: 20px;
    }
`
const Right = styled.p`
    font-size: 36px;
    font-family: 'Niconne', cursive;  
    position: absolute;
    right: 0; 
    margin-right: 13%;
    opacity: 0.9;
    @media screen and (max-width: 720px) {
        width: 120px;
        margin-right: 0;
        font-size: 20px;
    }
`