import styled from "styled-components"

export default function  TimelineScreen() {
    return(
        <StyledTimelineScreen>
            <StyledTimeline src="https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=1oO3bChz4tHQSHlP3bptQIello_KWuYjWLbEBmSAzaB0&font=Default&lang=en&initial_zoom=2&height=650"/>
        </StyledTimelineScreen>
    )
}

const StyledTimelineScreen = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledTimeline = styled.iframe`
    width: 100%;
    height: 800px;
`