import styled from "styled-components";

export default function QuotesScreen() {
    return (
        <StyledQuotesScreen>
            <ListSection>
                <Header style={{color: "#6836AA"}}>Ale's Quotes</Header>
                <ul>
                    <AleQuote>I'm still waiting you fucker</AleQuote>
                    <AleQuote>Hold on a bit. I need to do some math</AleQuote>
                    <AleQuote>(20/Dec/20) I'm so in shock I laughed in english jajajajajajjaa</AleQuote>
                    <AleQuote>(29/Dec/20) Imagine we travel together and we both have like 2k pics of nature and 2 pics of us</AleQuote>
                    <AleQuote>(21/Feb/21) Like the* jajajaja I eat words, you misspell. Together we can ruin english</AleQuote>
                    <AleQuote>(23/Feb/21) I'm just horny don't mind me</AleQuote>
                    <AleQuote>(29/May/21) I like sad songs. They're good for when you're feeling down so you can feel even worse</AleQuote>
                    <AleQuote>(14/Aug/21) Maybe they should stop saying she won't make it so that she actually doesn't</AleQuote>
                    <AleQuote>(14/Aug/21) You're such a weirdough. LOOK WHO'S TALKING, THE OTHER WEIRDOUGH</AleQuote>
                    <AleQuote>(19/Aug/21) Oh my god. I can hear one pigeon trying to fuck another pigeon</AleQuote>
                    <AleQuote>(19/Aug/21) Mehdi: Are you still in bed? <br/> Ale: Of course I am. Where else would I be?</AleQuote>
                    <AleQuote>(23/Sep/21) Your lack of margin gives me a stroke</AleQuote>
                    <AleQuote>(27/Oct/21) One Halloween you can be the ladybird and I can be the leaf</AleQuote>
                    <AleQuote>(18/Nov/21) I can't believe I'm a laptop or a phone</AleQuote>
                </ul>
            </ListSection>
            <ListSection>
                <Header style={{color: "#AA1B1D"}}>Mehdi's Quotes</Header>
                <ul>
                    <MehdiQuote>Do you know how people have bank accounts with banks?</MehdiQuote>
                    <MehdiQuote>The simple stuff is quite simple</MehdiQuote>
                    <MehdiQuote>A Brit talking to another Brit: We don't have USA today in this country</MehdiQuote>
                    <MehdiQuote>I am not Harry Potter but I am... Mehdi</MehdiQuote>
                    <MehdiQuote>Ale: I don't even know what I'm doing <br/> Mehdi: What are you doing? <br/> Ale: <i>Facepalm</i></MehdiQuote>
                    <MehdiQuote>Wow is that all I'm worth to you? A bag of Maltesers</MehdiQuote>
                    <MehdiQuote>Ah</MehdiQuote>
                    <MehdiQuote><i>Sees skeleton</i> <br/> Is it dead?... Oh of course it's dead</MehdiQuote>
                    <MehdiQuote>What if we were all bad people in our past lives and living on Earth is actually hell?</MehdiQuote>
                    <MehdiQuote>And we'll all just be a bunch of boobs</MehdiQuote>
                    <MehdiQuote>Hey when boobs are at stake, anything is possible</MehdiQuote>
                    <MehdiQuote>I'm just a phone</MehdiQuote>
                    <MehdiQuote>It's funny, the idea that dentists have to see another dentist to get their teeth checked. Like imagine if they could just check their own teeth</MehdiQuote>
                    <MehdiQuote>What's with the pigeon? <br/> - Ale explains <i>20 minutes later</i> <br/> What's with the pigeon?</MehdiQuote>
                    <MehdiQuote>It's better to be a weirdough than a sourdough</MehdiQuote>
                    <MehdiQuote>(11/Jun/21) My teeth were full of teeth</MehdiQuote>
                    <MehdiQuote>(12/Jun/21) I got me earphones</MehdiQuote>
                    <MehdiQuote>(25/Jun/21) The user can use</MehdiQuote>
                    <MehdiQuote>(27/Jul/21) I would so play with myself now if I didn't have to wake up tomorrow</MehdiQuote>
                    <MehdiQuote>(17/Aug/21) ... or Thursday night since I don't have to wake up on Friday</MehdiQuote>
                    <MehdiQuote>(17/Sep/21) I accidentally pressed it by accident</MehdiQuote>
                    <MehdiQuote>(21/Nov/21) I can touch your boobs but I can't touch your rolls?</MehdiQuote>
                    <MehdiQuote>(25/Nov/21) Omg Ale you know what I mean</MehdiQuote>
                </ul>
            </ListSection>
        </StyledQuotesScreen>
    )
}

const StyledQuotesScreen = styled.div`
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 720px) {
        flex-wrap: wrap;
    }
`

const ListSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Header = styled.p`
    font-size: 40px;
    font-family: Catamaran;
    margin-top: 24px;
`

const AleQuote = styled.li`
    font-size: 24px;
    max-width: 800px;
    margin-top: 12px;
    font-family: Catamaran;
`

const MehdiQuote = styled.li`
    font-size: 24px;
    max-width: 800px;
    margin-top: 12px;
    font-family: Catamaran;
`