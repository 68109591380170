import styled from "styled-components";

export default function MeddishScreen() {
    let wordElems: JSX.Element[] = []

    words.forEach(word => {
        wordElems.push(Word(word))
    })

    return (
        <StyledMeddishScreen>
            <Header>Meddish Dictionary</Header>
            <StyledList>
                {wordElems}
            </StyledList>
        </StyledMeddishScreen>
    )
}

const StyledMeddishScreen = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Header = styled.div`
    font-size: 40px;
    font-family: Catamaran;
    margin-top: 32px;
`

const StyledList = styled.ol`
    margin-top: 12px;
`


function Word(word: string) {
    return <StyledWord>{word}</StyledWord>
}

const StyledWord = styled.li`
    font-family: Catamaran;
    font-size: 28px;
`

const words = [
    "Hahas",
    "Welly",
    "Domt",
    "Fruday",
    "Lpl",
    "Menon",
    "Misleadong",
    "Oit",
    "Yoi",
    "Fone",
    "Agai",
    "Edapt",
    "Desktyop",
    "Cococuny",
    "Sone",
    "Nadded",
    "Taz",
    "Tihjt",
    "Lile",
    "Bame",
    "Indo",
    "Deaktyop",
    "Hapoen",
    "Spery",
    "Nsound",
    "Pronouncr",
    "O'regan",
    "Gaha",
    "Chewsdya",
    "Chewsdawy",
    "Shod",
    "Sjpd",
    "Kust",
    "U-fair",
    "Eon't",
    "Omh",
    "Youre",
    "Nicew",
    "Ncie",
    "U-day",
    "Theyre",
    "Bith",
    "Spajifh",
    "Isbt",
    "Iy",
    "Anoyher",
    "Onr",
    "Thoughj",
    "Nvn",
    "Goo",
    "Hete",
    "Desity",
    "Hwat",
    "Jis",
    "Anywhete",
    "Funnt",
    "Mighjt",
    "Gou",
    "Muvh",
    "Aboancake",
    "Haopy",
    "Fornuou",
    "Thanjs",
    "Wau",
    "Anytgubg",
    "Erong",
    "Hace",
    "Gallo",
    "Fice",
    "Oancake",
    "Cerythibg",
    "Breams",
    "Gruday",
    "Knowbright",
    "Wafrr",
    "Kkow",
    "Indo",
    "Mrch",
    "Hwat",
    "Lmso",
    "Guve",
    "Strabge",
    "Peolle",
    "Bubch",
    "Thus",
    "Gokay",
    "Dint",
    "Wah",
    "Dindt",
    "Thunk",
    "Diidnt",
    "Blsme",
    "Ypu",
    "Bloodu",
    "Jist",
    "Bettwr",
    "Bbetter",
    "Thag",
    "Pujamas",
    "Nrecr",
    "Mont",
    "Hsgshsy",
    "Thunk",
    "Agaib",
    "Talkimg",
    "Shut",
    "Ho",
    "Ui",
    "Suhi",
    "Happp",
    "Earlu",
    "Lile",
    "Tbag",
    "Wsnt",
    "Seear",
    "Nit",
    "Sire",
    "Firgured",
    "Amout",
    "Dkne",
    "Ofbuou",
    "Gello",
    "Thay",
    "Omot",
    "Minot",
    "Sabings",
    "Swuad",
    "Tbeyre",
    "Respectifully",
    "Whay",
    "Mena",
    "Necause",
    "Nooe",
    "Uo",
    "Fick",
    "Maube",
    "Gell",
    "Morbing",
    "Ahoulsnt",
    "Itnto",
    "Yourr",
    "Vhost",
    "Finsihed",
    "Wel",
    "Ellow",
    "Meamt",
    "Mejdi",
    "Irs",
    "Whu",
    "Reel",
    "Withou",
    "Uou",
    "Ni",
    "Tbis",
    "Heatts",
    "Wiw",
    "Nnot",
    "Nad",
    "Thrn",
    "Wjoops",
    "Meam",
    "Whia",
    "Whag",
    "Nigght",
    "Goure",
    "Aske",
    "Dyou",
    "Ghere",
    "Ot",
    "Who'x",
    "Hs",
    "Mot",
    "Pooks",
    "Happenee",
    "Cocunts",
    "Engkish",
    "Mims",
    "Knkw",
    "Eues",
    "Ong",
    "Momey",
    "Dod",
    "Getton",
    "Hallow",
    "Wabba",
    "Cstch",
    "Hpw",
    "Jahat",
    "Burriot",
    "Uoi",
    "Popvorn",
    "Fid",
    "Jot",
    "Fonsihed",
    "Gey",
    "Xant",
    "Ar",
    "Dix",
    "Neber",
    "Kn",
    "Thrr",
    "Hwllow",
    "Mdoe",
    "Fonished",
    "Alenos",
    "Talkimgt",
    "Awaje",
    "Nether",
    "Syaing",
    "Mahbe",
    "Mornjing",
    "Chesway",
    "Wjo",
    "Lnows",
    "Whag",
    "Goid",
    "Sllow",
    "Spash",
    "Yeab",
    "Groovu",
    "Kmeo",
    "Yhat",
    "Teady",
    "Wht",
    "Nsuggle",
    "Thwee",
    "Reasy",
    "Yeha",
    "Wouls",
    "Sleepw",
    "Thimbs",
    "Attacj",
    "Chewdaw",
    "Fkod",
    "Hin",
    "Pabic",
    "Inomow",
    "Fwee",
    "Bedore",
    "Wattwr",
    "Cjesday",
    "Pmg",
    "Frudau",
    "Grudau",
    "Helloiw",
    "Isea",
    "Hei",
    "Hu",
    "Wheewe",
    "Finizhed",
    "Ciddle",
    "Tobgue",
    "Mostake",
    "Zfuck",
    "Rughtt",
    "Ezist",
    "Timr",
    "Akso",
    "Thenk",
    "Earing",
    "Amnd",
    "Byw",
    "Thoyh",
    "Oterh",
    "Cjarge",
    "Forogt",
    "Tomor",
    "Redy",
    "Hugjt",
    "Myw",
    "Maube",
    "Mointain",
    "Striaght",
    "Wokr",
    "Becuse",
    "Kissee",
    "Eow",
    "Ledt",
    "Smole",
    "Ym",
    "Verison",
    "Weetie",
    "Tow",
    "Perios",
    "Brwaf",
    "Engame"
]