import { Link } from "react-router-dom"
import Slogan from "./Slogan"
import logo from "../res/images/logo.png"
import styled from "styled-components"

export default function Header() {
    return (
        <StyledHeader>
            <Slogan />
            <Logo src={logo} />
            <BrandName>
                <AleText>ale</AleText>
                <MehdiText>hdi</MehdiText>
                <TLDText>.com</TLDText>
            </BrandName>
            <StyledMenu>
                <Link to="/quotes" style={{ textDecoration: "none" }}>
                    <StyledMenuItem>quotes</StyledMenuItem>
                </Link>
                <Link to="/" style={{ textDecoration: "none" }}>
                    <StyledMenuItem>timeline</StyledMenuItem>
                </Link>
                <Link to="/todo" style={{ textDecoration: "none" }}>
                    <StyledMenuItem>to-do</StyledMenuItem>
                </Link>
                <Link to="/meddish" style={{ textDecoration: "none" }}>
                    <StyledMenuItem>meddish</StyledMenuItem>
                </Link>
            </StyledMenu>
        </StyledHeader>
    )
}

const StyledHeader = styled.div`
    width: 100%;
    height: 120px;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.35);
    display: flex;
    flex-direction: column;
    align-items: center;
`

const StyledMenu = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
`

const StyledMenuItem = styled.p`
    font-size: 18px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 600;
    text-decoration: none;
    margin-left: 12px;
    margin-right: 12px;
`

const Logo = styled.img`
    width: 64px;
    height: 64px;
`

const BrandName = styled.div`
    display: flex;
    margin-top: -14px;
`

const AleText = styled.p`
    font-size: 20px;
    font-family: 'Sarabun', sans-serif;
    margin: 0;
    color: #6836AA;
`

const MehdiText = styled.p`
    font-size: 20px;
    font-family: 'Sarabun', sans-serif;
    margin: 0;
    color: #AA1B1D;
`

const TLDText = styled.p`
    font-size: 20px;
    font-family: 'Sarabun', sans-serif;
    margin-top: -11px;
    margin: 0;
    color: #000000;
`